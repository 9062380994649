import { Grid, Stack, SxProps, Theme, Typography, Box, IconButton } from '@mui/material';
import owlReading from '../../assets/images/owl-reading.svg';
import { IndexItem } from '../common/index-page-components/IndexItem';
import React, { useEffect, useRef, useState } from 'react';
import { TableOfContents } from '@yellow-owl/client-sdk';
import { BookComponent } from '../common/BookComponent';
import { ACTIVITY } from '../../route/router';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const gridItemsStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  img: {
    width: '12vh',
  },
};

const rightPaperGridHeader: SxProps<Theme> = {
  position: 'absolute',
  top: '7vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const leftPageContainer: SxProps<Theme> = {
  height: '90vh',
  width: '80%',
  overflowY: 'scroll',
  marginTop: '3vh',
  paddingRight: '2vh',
  position: 'relative',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

const leftPageContentTitle: SxProps<Theme> = {
  fontWeight: '500',
  fontSize: '4vh',
  fontFamily: 'CormorantGaramondRegular',
  textAlign: 'center',
};

const leftPageContent: SxProps<Theme> = {
  fontWeight: '700',
  fontSize: '2vh',
  fontFamily: 'CormorantGaramondRegular',
  textAlign: 'center',
  marginBottom: '3vh',
};

const rightGridRow: SxProps<Theme> = {
  width: '35vw',
  marginBottom: '5vh',
};

const activitiesContanier: SxProps<Theme> = {
  position: 'absolute',
  top: '20vh',
  height: '80%',
  overflowX: 'hidden',
  overflowY: 'scroll',
  // background: 'lightblue',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

const scrollBtnStyle: SxProps<Theme> = {
  position: 'absolute',
  width: '45px',
  height: '45px',
  bottom: '10px',
  right: '20px',
  cursor: 'pointer',
  backgroundColor: '#3b3077',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10%',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
  zIndex: '1',
  transition: 'bottom 0.3s ease-in-out',
  '&:hover': {
    bottom: '15px',
  },
  // animation: 'bounce 5s ease infinite',
};

const scrollIcon: SxProps<Theme> = {
  color: '#fbec54',
  fontSize: '4.5vh',
};

export type IndexPageComponentProps = {
  tableOfContents: TableOfContents;
  magazineId: string | null;
  magazineName: string | null;
};

export type NavigationParams = {
  pathname: string;
  search: string;
  state: {
    key: string;
    magazineName: string;
  };
};

export const IndexPage: React.FC<IndexPageComponentProps> = ({ tableOfContents, magazineId, magazineName }) => {
  const leftPageContentRef = useRef<HTMLDivElement | null>(null);
  const rightPageContentRef = useRef<HTMLDivElement | null>(null);
  const [showLeftScrollButton, setLeftShowScrollButton] = useState(false);
  const [showRightScrollButton, setRightShowScrollButton] = useState(false);

  const getTypeAndUrl = (activity: any): { type: string; url: string } => {
    const activityStatus = activity.iconCompletedState ?? activity.iconIncompleteState;
    return {
      type: activityStatus.type,
      url: activityStatus.mediaUrl || '',
    };
  };

  const handleScrollDown = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      const newScrollTop = ref.current.scrollTop + ref.current.clientHeight / 2;
      ref.current.scrollTo({
        top: newScrollTop,
        behavior: 'smooth',
      });
    }
  };

  const updateScrollButtons = () => {
    if (leftPageContentRef.current) {
      setLeftShowScrollButton(leftPageContentRef.current.scrollHeight > leftPageContentRef.current.clientHeight);
    }
    if (rightPageContentRef.current) {
      setRightShowScrollButton(rightPageContentRef.current.scrollHeight > rightPageContentRef.current.clientHeight);
    }
  };

  const leftContent = (
    <>
      <Stack sx={leftPageContainer} ref={leftPageContentRef} justifyContent={showLeftScrollButton ? 'start' : 'center'}>
        <Typography align={'center'} gutterBottom sx={leftPageContentTitle} variant={'subtitle2'}>
          <b>Hellooo 🎉</b>
        </Typography>
        <Stack sx={leftPageContent}>
          <div dangerouslySetInnerHTML={{ __html: tableOfContents.description }} className={'ck-content'}></div>
        </Stack>
      </Stack>
    </>
  );

  const rightContent = (
    <>
      <Stack direction={'row'} sx={rightPaperGridHeader}>
        <img src={owlReading} alt={'owlReading'} />
        <Typography align={'center'} gutterBottom sx={leftPageContentTitle} variant={'subtitle2'}>
          <b>What’s Inside?</b>
        </Typography>
      </Stack>
      <Box sx={activitiesContanier} ref={rightPageContentRef}>
        <Grid container sx={rightGridRow}>
          {tableOfContents.activities.map((activity, index) => (
            <Grid key={index} item xs={4} sx={gridItemsStyle}>
              <IndexItem
                navigateTo={{
                  pathname: `/${ACTIVITY}`,
                  search: `?id=${magazineId}&actid=${activity.id}`,
                  state: { key: activity.displayName, magazineName: magazineName! },
                }}
                itemIconImage={getTypeAndUrl(activity)}
                itemName={activity.displayName}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '9vw',
                  height: '8vw',
                  paddingTop: '2vh',
                  position: 'relative',
                  cursor: 'pointer',
                }}
                isCompleted={activity.completed}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );

  const leftContentScrollWarp = (
    <Box display={'flex'} justifyContent={'center'} position={'relative'}>
      {leftContent}
      {showLeftScrollButton && (
        <Box sx={scrollBtnStyle}>
          <IconButton onClick={() => handleScrollDown(leftPageContentRef)}>
            <KeyboardArrowDownIcon sx={scrollIcon} />
          </IconButton>
        </Box>
      )}
    </Box>
  );

  const rightContentScrollWarp = (
    <>
      {rightContent}
      {showRightScrollButton && (
        <Box sx={scrollBtnStyle}>
          <IconButton onClick={() => handleScrollDown(rightPageContentRef)}>
            <KeyboardArrowDownIcon sx={scrollIcon} />
          </IconButton>
        </Box>
      )}
    </>
  );

  useEffect(() => {
    const listenResize = new ResizeObserver((entries) => {
      entries.forEach(() => {
        updateScrollButtons();
      });
    });
    listenResize.observe(leftPageContentRef.current!);
    listenResize.observe(leftPageContentRef.current!);
  }, [leftContentScrollWarp, rightContentScrollWarp]);

  return <BookComponent leftContent={leftContentScrollWarp} rightContent={rightContentScrollWarp} hideButtons={true} />;
};
