export const yellowOwlMessages: Record<string, string> = {
  MOBILE_BROWSER_NOT_SUPPORTED: 'Oops ! Mobile browser not supported',
  ENTER_OTP_SENT_TO_MOBILE_NUMBER: 'Enter the OTP sent to your mobile number.',
  DIDNT_RECEIVE_OTP: 'Didn’t receive OTP?',
  RESENT_OTP: 'Resend OTP',
  PLEASE_ENTER_YOUR_MOBILE_NUMBER: 'Please enter your mobile number.',
  HOW_DO_WE_CALL_YOU: 'How do we call you?',
  TELL_US_YOUR_NAME: 'Tell us your name!',
  HOW_OLD_ARE_YOU: 'How old are you?',
  PLEASE_SHARE_YOUR_PARENTS_EMAIL: 'Please share your parents’ e-mail id.',
  NO_ISSUES_AVAILABLE: 'No issues available',
  NEXT_ISSUE_AVAILABLE_ON: 'Next issue available on',
  TERMS_AND_CONDITIONS: 'Terms and Conditions',
  PRIVACY_POLICY: 'Privacy Policy',
  CONTACT_US: 'Contact Us',
  NOTE_TO_PARENTS: 'Note to parents',
  termsAndConditions: ' If you do not agree with any of these terms, please do not use our platform.',
  eligibility:
    ' Eligibility: Our platform is intended for children aged 6-12 years old. Parents or guardians must provide their consent for their children to use our platform.',
  userAccounts:
    'User Accounts: To access certain features of our platform, you may be required to create a user account. You agree to provide accurate and complete information when creating your account and to keep your login details secure.',
  content:
    'Content: Our platform provides children`s skill magazines for educational and entertainment purposes. We do not guarantee the accuracy or reliability of any content on our platform.We reserve the right to remove any content that we deem inappropriate or that violates these Terms and Conditions.',
  intellectualProperty:
    'Intellectual Property: All content on our platform, including text, images, graphics, videos, and software,is protected by copyright, trademark, and other intellectual property laws. You may not use any content on our platform without our prior written consent.',
  userConduct:
    'User Conduct: You agree to use our platform for lawful purposes only. You may not upload or transmit any content that is harmful, obscene, or offensive.You may not attempt to gain unauthorised access to our platform or interfere with its operation. ',
  privacy:
    'Privacy: We are committed to protecting your privacy. We will collect and use your personal information in accordance with our Privacy Policy.',
  termination: 'Termination: We may terminate your access to our platform at any time without notice or liability.',
  contactAdmin:
    'If you have any questions or concerns about our terms and conditions, please contact us at admin@yellowowl.app.',
  privacyPolicy: 'We take your privacy seriously. Please read the following privacy policy carefully:',
  collectionOfInformation:
    'Collection of Information: We may collect personal information, such as your name and email address, when you create an account on our platform. We may also collect non-personal information, such as your IP address and browser type.',
  useOfInformation:
    'Use of Information: We use your personal information to provide you with access to our platform and to communicate with you about your account. We may also use your information to improve our platform and to personalize your experience. We may use non-personal information to analyze trends and to track user behavior on our platform.',
  disclosureOfInformation:
    'Disclosure of Information: We do not sell, trade, or otherwise transfer your personal information to third parties.',
  security:
    'Security: We use industry-standard security measures to protect your personal information from unauthorised access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of your information.',
  thirdParty:
    'Third-Party Links: Our platform may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites.',
  childrensPrivacy:
    "Children's Privacy: Our platform is intended for children aged 6-12 years old. We do not collect personal information from children without the consent of their parents or guardians. We do not knowingly collect or use personal information from children under 13 years of age.",
  changesToPrivacyPolicy:
    'Changes to Privacy Policy: We reserve the right to modify this privacy policy at any time without notice. Your continued use of our platform following any changes to this privacy policy constitutes your acceptance of the changes.',
  adminContact:
    'If you have any questions or concerns about our privacy policy, please contact us at admin@yellowowl.app.',
  DEAR_PARENT: 'Dear Parent',
  DEAR_PARENT_DESCRIPTION:
    'If you are here and reading this, we believe you must be one of those curious parents who goes that extra mile for their child. :)',
  THANK_YOU_YELLOW_OWL: 'Thank you for showing interest in Yellow Owl.',
  WHO_ARE_WE: 'Who are we ?',
  WHO_ARE_WE_DESCRIPTION:
    ' We are a small team of education and technology enthusiasts who are ambassadors of skill-building in young learners. Yellow Owl, an online interactive magazine for children is a small step in this direction.',
  WHY_YELLOW_OWL: 'Why Yellow Owl?',
  WHY_YELLOW_OWL_DESCRIPTION:
    ' Yellow Owl strives to provide your child an interactive platform where they can both learn independently as well as ',
  ENGAGE_WITH_PEERS: 'engage with their peers',
  THROUGH_INTERESTING_AND: 'through interesting and',
  MEANINGFUL_ACTIVITIES: 'meaningful activities',
  TO: ' to',
  EXPLORE_AND_BUILD: 'explore and build key skills',
  AT_THEIR_OWN_PACE: 'at their own pace.',
  WHY_BUILDING_SKILLS_IS_IMPORTANT: ' Why building skills is important?',
  WHY_BUILDING_SKILLS_IS_IMPORTANT_DESCRIPTION:
    'Gone are the days when knowledge was power. Today, google is not just a search engine but is a functional verb in our vocabulary. We wonder then, if not knowledge what is it that young children of today need to gain?',
  RESEARCH_AND_EVIDENCE:
    'We have enough research and evidence to validate the need for focussing on building transferable 21st century skills.',
  RESEARCH_LINKS_HERE: 'Research links HERE.',
  KEY_SKILLS: 'Key skills that our readers will be exploring',
  YELLOW_OWL_COMMUNITY: 'Hoping to have you be part of the Yellow Owl community.',
  TEAM_YELLOW_OWL: 'Team Yellow Owl',
  commentsAndQuestions: 'For Comments and Questions',
  writeToUsAt: 'Write to us at',
  email: 'admin@yellowowl.app',
  callUsAt: 'Call us at',
  phoneNumber: '+91-9944228272',
  wouldYouRecommend: 'Would you recommend',
  yellowOwlToOthers: 'Yellow Owl to others?',
  yes: 'Yes!',
  no: 'No!',
  HOW_DID_YOU_LIKE_THE_ACTIVITY: 'How did you like the activity?',
  PhoneNo: 'Phone Number',
  error: 'Error',
  pleaseSelectACountryFirst: 'Please select a country first',
  pleaseSelectCountryAndStateFirst: 'Please select a country and a state first',
  ITS_OKAY: `It's Okay!`,
  ITS_GOOD: `It's Good!`,
  AMAZING: `It's Amazing!`,
  ITS_OKAY_EMOJI: '&#x1F611;',
  ITS_GOOD_EMOJI: '&#x1F642;',
  AMAZING_EMOJI: '&#x1F929;',
  IAM_DONE: `I'm Done`,
};
