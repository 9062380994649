import { createContext, useContext, useState, ReactNode } from 'react';
import { getCodeForCountry } from '../utils/commonUtils';

export const TOKEN_KEY = 'token';
export const TENANT_ID_KEY = 'tenantId';
export const USER_ID_KEY = 'userId';
export const PHONE_NUMBER_KEY = 'phoneNumber';
export const EMAIL_KEY = 'parentEmail';
export const USER_NAME_KEY = 'name';
export const CATEGORY_ID_KEY = 'categoryId';
export const COUNTRY_CODE_KEY = 'countryCode';
export const AGE_KEY = 'age';

type LocalStorageState = {
  token: string | null;
  tenantId: string | null;
  userId: string | null;
  phoneNumber: string | null;
  categoryId: string | null;
  age: string | null;
  name: string | null;
  parentEmail: string | null;
  countryCode: string;
};

type LocalStorageContextType = {
  state: LocalStorageState;
  updateLocalStorage: (key: keyof LocalStorageState, value: string | null) => void;
  getLocalStorage: (key: keyof LocalStorageState) => string | null;
};

const LocalStorageContext = createContext<LocalStorageContextType | undefined>(undefined);

export const LocalStorageProvider = ({ children }: { children: ReactNode }) => {
  const countryCodeNumber = localStorage.getItem(COUNTRY_CODE_KEY);

  const [state, setState] = useState<LocalStorageState>(() => ({
    token: localStorage.getItem(TOKEN_KEY),
    name: localStorage.getItem(USER_NAME_KEY),
    tenantId: localStorage.getItem(TENANT_ID_KEY),
    userId: localStorage.getItem(USER_ID_KEY),
    phoneNumber: localStorage.getItem(PHONE_NUMBER_KEY),
    parentEmail: localStorage.getItem(EMAIL_KEY),
    categoryId: localStorage.getItem(CATEGORY_ID_KEY),
    countryCode: countryCodeNumber ? getCodeForCountry(countryCodeNumber) : 'IN',
    age: localStorage.getItem(AGE_KEY),
  }));

  const updateLocalStorage = (key: keyof LocalStorageState, value: string | null) => {
    setState((prevState) => {
      const newState = { ...prevState, [key]: value };
      return newState;
    });
    localStorage.setItem(key, value || '');
  };

  const getLocalStorage = (key: keyof LocalStorageState): string | null => {
    return localStorage.getItem(key);
  };

  return (
    <LocalStorageContext.Provider value={{ state, updateLocalStorage, getLocalStorage }}>
      {children}
    </LocalStorageContext.Provider>
  );
};

export const useLocalStorage = (): LocalStorageContextType => {
  const context = useContext(LocalStorageContext);
  if (!context) {
    throw new Error('useLocalStorage must be used within a LocalStorageProvider');
  }
  return context;
};
