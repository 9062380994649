import { useState } from 'react';

export const PrivacyPolicy: React.FC = () => {
  const sectionStyle = {
    marginBottom: '20px',
  };

  const headingStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#2C3E50',
  };

  const textStyle = {
    fontSize: '16px',
    lineHeight: '1.6',
    color: '#34495E',
  };

  const [showDialog, setShowDialog] = useState(false);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailError, setEmailError] = useState('');

  // Email validation function
  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleDeleteRequest = async () => {
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address');
      return;
    }
    setIsSubmitting(true);
    setEmailError('');

    // Simulate async request (e.g., API call to request account deletion)
    setTimeout(() => {
      setIsSubmitting(false);
      alert(`Account deletion requested for ${email}`);
      setShowDialog(false);
      setEmail('');
    }, 2000);
  };

  const handleCancel = () => {
    setShowDialog(false);
    setEmail('');
    setEmailError('');
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (emailError && validateEmail(e.target.value)) {
      setEmailError(''); // Clear error if email becomes valid
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1 style={{ fontSize: '32px', fontWeight: 'bold', color: '#2980B9' }}>Privacy Policy</h1>
      <p style={textStyle}>
        <strong>Effective Date: [06/10/2024]</strong>
      </p>
      <p style={textStyle}>
        We value your privacy and are committed to protecting your personal data. This Privacy Policy outlines how we
        collect, use, and protect your information when you use our skill-building app for kids (the “Yellow Owl”). By
        using the App, you agree to the terms outlined in this Privacy Policy.
      </p>

      <div style={sectionStyle}>
        <h2 style={headingStyle}>1. Information We Collect</h2>
        <p style={textStyle}>We collect personal information to provide our services, including:</p>
        <ul style={{ paddingLeft: '20px', color: '#34495E' }}>
          <li>Phone Number</li>
          <li>Username</li>
          <li>Age</li>
          <li>Email Address</li>
          <li>Country, State, and City</li>
        </ul>
        <p style={textStyle}>
          We do not knowingly collect personal information from children under 13 without parental consent. If we
          discover that we have collected such information, we will delete it promptly.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={headingStyle}>2. How We Use the Information</h2>
        <p style={textStyle}>We use the information collected for the following purposes:</p>
        <ul style={{ paddingLeft: '20px', color: '#34495E' }}>
          <li>Personalization of content</li>
          <li>Communication</li>
          <li>Content moderation</li>
          <li>Account management</li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h2 style={headingStyle}>3. Sharing Your Information</h2>
        <p style={textStyle}>
          We do not sell, rent, or lease your personal information. We may share your data only in legal circumstances
          or with service providers assisting us in delivering the App’s services.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={headingStyle}>4. Your Rights</h2>
        <p style={textStyle}>
          You have the right to access, correct, or request deletion of your personal information. To exercise these
          rights, contact us at [admin@yellowowl.app].
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={headingStyle}>5. Data Security</h2>
        <p style={textStyle}>
          We take reasonable precautions to protect your data. If you have concerns, contact us at
          [admin@yellowowl.app].
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={headingStyle}>Request Account Deletion</h2>
        <p style={textStyle}>
          If you wish to delete your account, please{' '}
          <a
            onClick={() => setShowDialog(true)}
            style={{ color: '#2980B9', textDecoration: 'underline', cursor: 'pointer' }}
          >
            click here to request for deleting the account
          </a>
          .
        </p>
      </div>

      {/* Delete Account Dialog */}
      {showDialog && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              maxWidth: '400px',
              margin: '10% auto',
              padding: '20px',
              borderRadius: '8px',
            }}
          >
            <h2 style={headingStyle}>Request Account Deletion</h2>
            <p style={textStyle}>Please provide your email and phone number to request account deletion:</p>

            <input
              type='email'
              placeholder='Enter your email'
              value={email}
              onChange={handleEmailChange}
              style={{
                width: '300px',
                padding: '10px',
                marginBottom: '10px',
                fontSize: '16px',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
            />

            {emailError && <p style={{ color: 'red', marginBottom: '10px' }}>{emailError}</p>}

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button
                onClick={handleCancel}
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#ccc',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                Cancel
              </button>

              <button
                onClick={handleDeleteRequest}
                disabled={isSubmitting}
                style={{
                  padding: '10px 20px',
                  backgroundColor: isSubmitting ? '#7f8c8d' : '#2980B9',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: isSubmitting ? 'not-allowed' : 'pointer',
                }}
              >
                {isSubmitting ? 'Sending...' : 'Send'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
