import React, { useEffect } from 'react';
import { Box, Grid, Stack, Theme, Typography, Modal, SxProps, IconButton } from '@mui/material';
import ReactFlagsSelect from 'react-flags-select';
import 'react-phone-input-2/lib/bootstrap.css';
import { ArrowForward } from '@mui/icons-material';
import { ChangeEvent, KeyboardEvent } from 'react';
import curevLine from '../../assets/icons/curve-line.svg';
import owlHeader from '../../assets/icons/owl-header.svg';
import { yellowOwlMessages } from '../../Constants';

export type CountryCode = {
  key: string;
  value: string;
};

export const SUPPORTED_COUNTRY_CODES: CountryCode[] = [
  { key: 'US', value: '+1' },
  { key: 'IN', value: '+91' },
  { key: 'CA', value: '+1' },
  { key: 'DE', value: '+49' },
];

const headerBox: SxProps<Theme> = {
  paddingTop: '1vh',
  minHeight: '74px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transform: 'translateX(-1.3%)',
};

const yellowText: SxProps<Theme> = {
  fontFamily: 'MacondoRegular',
  fontWeight: '400',
  fontSize: {
    xs: '4vw',
    sm: '3vw',
    md: '5vh',
  },
  lineHeight: '16px',
  fontStyle: 'normal',
  color: '#3C3077',
};

const owlText: SxProps<Theme> = {
  fontFamily: 'MacondoRegular',
  fontWeight: '400',
  fontSize: {
    xs: '4vw',
    sm: '3vw',
    md: '5vh',
  },
  lineHeight: '16px',
  fontStyle: 'normal',
  color: '#3C3077',
  marginLeft: '14px',
};

const imgSx: SxProps<Theme> = {
  width: {
    xs: '50px',
    sm: '60px',
    md: '90px',
  },
  height: {
    xs: '50px',
    sm: '60px',
    md: '91px',
  },
  marginLeft: '14px',
};

const formBox: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  height: '50vh',
};

const enterMobileNo: SxProps<Theme> = {
  fontFamily: 'CormorantGaramondRegular',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: {
    xs: '3vw',
    sm: '2vw',
    md: '1vw',
  },
  lineHeight: '16px',
};

const flagSelect: SxProps<Theme> = {
  button: {
    background: '#FFFFFF',
    height: '7.4vh',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: {
      xs: '3vw',
      sm: '2vw',
      md: '1vw',
    },
    letterSpacing: '1px',
    border: '2px solid #3C3077',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
  },
  position: 'relative',
  width: {
    xs: '20vw',
    sm: '15vw',
    md: '10vw',
  },
  svg: {
    height: '5vh',
    width: '3vw',
  },
};

const yellowOwlInput: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '7vh',
  marginBottom: '4px',
  input: {
    fontFamily: 'RalewayRegular',
    background: '#FFFFFF',
    padding: '0 1vh',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: {
      xs: '3vw',
      sm: '2vw',
      md: '1vw',
    },
    letterSpacing: '0.1vw',
    border: 'none',
    borderRadius: '8px',
    outline: 'none',
    height: 'inherit',
  },
  button: {
    marginRight: '0.1vw',
  },
  borderRadius: '8px',
  border: '2px solid #3C3077',
  boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
  background: '#FFFFFF',
};

const forwardIcon: SxProps<Theme> = {
  borderRadius: '0.25rem',
  background: '#3C3077',
  height: '4rem',
  width: '4rem',
  ':hover': {
    backgroundColor: '#3C3077',
  },
};

const modalBox: SxProps<Theme> = {
  background: `url(${curevLine}), #FBEC53`,
  overflow: 'hidden',
  width: {
    xs: '60%',
    sm: '60%',
    md: '30%',
    lg: '30%',
    xl: '30%',
  },
  padding: '2rem',
  borderRadius: '8px',
  boxShadow: 24,
};

const modalCenter: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export type GetOTPPageComponentProps = {
  phoneNumber: string;
  countryCode: string;
  canSignIn: boolean;
  onCountryCodeChange(countryCode: string): void;
  onPhoneNumberChange(number: string): void;
  onRequestSignIn(): void;
  open: boolean;
  onClose(): void;
};

export const CommunityWallLogin: React.FC<GetOTPPageComponentProps> = ({
  phoneNumber,
  countryCode,
  canSignIn,
  onCountryCodeChange,
  onPhoneNumberChange,
  onRequestSignIn,
  open,
  onClose,
}) => {
  const handleKeyPressEvent = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onRequestSignIn();
    }
  };

  useEffect(() => {
    onCountryCodeChange('IN');
  }, []);

  return (
    <Modal open={open} onClose={onClose} sx={modalCenter}>
      <Box sx={modalBox}>
        <Grid container>
          <Grid item xs={12} sx={headerBox}>
            <Typography sx={yellowText} variant='body2'>
              Yellow
            </Typography>
            <Box component='img' src={owlHeader} sx={imgSx} alt='yellow-owl-header' />
            <Typography sx={owlText} variant='body2'>
              Owl
            </Typography>
          </Grid>
          <Grid item xs={12} sx={formBox}>
            <Box>
              <Typography align='left' marginBottom='16px' variant='subtitle1' component='p' sx={enterMobileNo}>
                {yellowOwlMessages.PLEASE_ENTER_YOUR_MOBILE_NUMBER}
              </Typography>
              <Stack
                spacing={1}
                direction={{ xs: 'column', sm: 'row' }}
                sx={{ flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}
              >
                <Box sx={flagSelect}>
                  <ReactFlagsSelect
                    selected={countryCode}
                    countries={SUPPORTED_COUNTRY_CODES.map((item) => item.key)}
                    onSelect={(code) => onCountryCodeChange(code)}
                    showOptionLabel={false}
                    showSelectedLabel={false}
                  />
                </Box>
                <Box sx={{ marginBottom: '20px' }}>
                  <Box sx={yellowOwlInput}>
                    <input
                      type='tel'
                      placeholder={yellowOwlMessages.PhoneNo}
                      value={phoneNumber}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => onPhoneNumberChange(e.target.value)}
                      onKeyDown={handleKeyPressEvent}
                    />
                    <IconButton disabled={!canSignIn} sx={forwardIcon} onClick={onRequestSignIn}>
                      <ArrowForward sx={{ color: '#FFFFFF' }} />
                    </IconButton>
                  </Box>
                </Box>
              </Stack>
              <br />
              <div id='recaptcha-container'></div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
