import React from 'react';

export const TermsAndConditions: React.FC = () => {
  const sectionStyle = {
    marginBottom: '20px',
  };

  const headingStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#2C3E50',
  };

  const textStyle = {
    fontSize: '16px',
    lineHeight: '1.6',
    color: '#5D6D7E',
  };

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1 style={{ fontSize: '32px', fontWeight: 'bold', color: '#2980B9' }}>Terms and Conditions</h1>
      <p style={textStyle}>
        <strong>Effective Date: [06/10/2024]</strong>
      </p>

      <div style={sectionStyle}>
        <h2 style={headingStyle}>1. Eligibility</h2>
        <p style={textStyle}>
          The App is designed for kids above age 3 with parental consent required for those under 13. By using the App,
          you agree to these terms.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={headingStyle}>2. User Accounts</h2>
        <p style={textStyle}>
          Users must provide accurate information when creating accounts. You are responsible for keeping your account
          secure.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={headingStyle}>3. User Content</h2>
        <p style={textStyle}>
          By submitting content, you grant us the right to use and display it on the app. All content is reviewed before
          publication.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={headingStyle}>4. Prohibited Activities</h2>
        <p style={textStyle}>
          You agree not to use the App for illegal activities or submit false or harmful content. We reserve the right
          to suspend or terminate access.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={headingStyle}>5. Limitation of Liability</h2>
        <p style={textStyle}>
          We are not liable for any indirect or consequential damages resulting from your use of the App. The App may
          have interruptions or errors.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={headingStyle}>6. Governing Law</h2>
        <p style={textStyle}>
          These Terms are governed by and construed in accordance with the laws of India. Any disputes arising out of or
          in connection with the App will be subject to the exclusive jurisdiction of the courts of Tamil Nadu, India.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={headingStyle}>7. Changes to These Terms</h2>
        <p style={textStyle}>
          We may update these Terms periodically, and your continued use of the App constitutes acceptance of those
          updates.
        </p>
      </div>
    </div>
  );
};
