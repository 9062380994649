import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { SxProps, Theme } from '@mui/system';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import yellowOwlLogo from '../../assets/icons/owl-sidepanel.svg';
import yellowOwlTitle from '../../assets/icons/owl-tilte.svg';
import cardMedal from '../../assets/images/card-medal.png';
import clickSoundFile from '../../assets/sounds/clickSound.mp3';
import NoResults from '../common/NoResultFound';
import { CommunityWallLoginContainer } from '../../containers/community-wall/CommunityWallLoginContainer';
import { LogoutComponent } from '../common/LogoutComponent';
import LoginIcon from '@mui/icons-material/Login';
import { ACTIVITY } from '../../route/router';

const AnimatedCard = motion(styled(Card)`
  padding: 10px;
  padding-bottom: 0px;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  margin-left: 5px;
`);

const containerStyle: SxProps<Theme> = {
  backgroundColor: '#F8EC6E',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
};

const appBarStyle: SxProps<Theme> = {
  backgroundColor: '#3c3075',
};

const toolbarStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
};

const toolbarLogoStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
};

const loginButtonStyle: SxProps<Theme> = {
  border: '3px solid #3c3075',
  color: 'white',
  backgroundColor: '#3c3075',
  fontFamily: 'Schoolbell',
  borderRadius: '4px',
  boxShadow: 'none',
  fontWeight: 'bold',
  fontSize: '14px',
  padding: '3px 6px',
  '&:hover': {
    backgroundColor: '#3c3075',
    color: 'white',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
};

const yellowOwlLogoBorderBox: SxProps<Theme> = {
  width: '40px',
  height: '40px',
  img: {
    width: '10vw',
    height: '10vw',
  },
  display: 'flex',
  background: 'linear-gradient(#3C3077, #3C3077) padding-box, linear-gradient(to top, #6649D8, #FBEC53) border-box',
  borderRadius: '50em',
  border: '3px solid transparent',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '1vh',
  marginTop: '1vh',
};

const titleStyle: SxProps<Theme> = {
  marginLeft: '15px',
  width: '140px',
  height: '70px',
  color: '#FFFF00',
  marginBottom: '10px',
};

const dropdownContainer: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  margin: '0px',
  paddingTop: '10px',
  marginRight: '10px',
};

const dropdownLabel: React.CSSProperties = {
  backgroundColor: '#7663D8',
  color: 'white',
  padding: '10px 15px',
  borderTopLeftRadius: '25px',
  borderBottomLeftRadius: '25px',
  fontFamily: 'inherit',
};

const dropdownSelectContainer: React.CSSProperties = {
  position: 'relative',
  display: 'inline-block',
  paddingLeft: '0px',
};

const dropdownSelectCommon: React.CSSProperties = {
  padding: '10px 15px',
  borderTopRightRadius: '25px',
  borderBottomRightRadius: '25px',
  border: 'none',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  appearance: 'none',
  WebkitAppearance: 'none',
  MozAppearance: 'none',
  position: 'relative',
  backgroundColor: 'white',
  color: '3C3077',
  minWidth: '100px',
  width: 'auto',
  outline: 'none',
  boxSizing: 'border-box',
  paddingRight: '30px',
  fontFamily: 'inherit',
};

const dateIntervalStyle: React.CSSProperties = {
  padding: '10px 15px',
  border: 'none',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  appearance: 'none',
  WebkitAppearance: 'none',
  MozAppearance: 'none',
  position: 'relative',
  backgroundColor: 'white',
  color: '3C3077',
  minWidth: '100px',
  width: 'auto',
  outline: 'none',
  boxSizing: 'border-box',
  paddingRight: '30px',
  fontFamily: 'inherit',
};

const dropdownArrow: React.CSSProperties = {
  position: 'absolute',
  top: '50%',
  right: '10px',
  pointerEvents: 'none',
  transform: 'translateY(-50%)',
  fontSize: '10px',
  color: '#6F4A8E',
};

const cardMediaStyle: SxProps<Theme> = {
  height: '260px',
  borderRadius: '7px',
  objectFit: 'cover',
};

const cardMagazineStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const profileIconStyle: SxProps<Theme> = {
  marginRight: '8px',
  borderRadius: '50%',
  width: '45px',
  height: '45px',
};

const gradeContainerStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#7663D8',
  borderRadius: '20px',
  padding: '2px 4px',
};

const gradeIconStyle: SxProps<Theme> = {
  width: '12px',
  height: '12px',
  marginRight: '4px',
};

const cardStudentName: SxProps<Theme> = {
  fontSize: '18px',
  margin: '0',
};

const cardStudentNameContent: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
};

const cardContentStyle: SxProps<Theme> = {
  paddingTop: '10px',
  paddingLeft: '0px',
  paddingRight: '0px',
  paddingBottom: '15px!important',
};

const magazineNameStyle: SxProps<Theme> = {
  paddingTop: '5px',
  fontSize: '15px',
};

const pageContainerStyle: SxProps<Theme> = {
  flexGrow: 1,
  padding: '20px 25px 0 25px',
  marginTop: '10px',
};

const headerTextStyle: SxProps<Theme> = {
  color: '#3c3075',
  fontFamily: 'MacondoRegular',
  paddingTop: '10px',
};

const cardsGridStyle: SxProps<Theme> = {
  justifyContent: 'flex-start',
  marginTop: '20px',
  marginBottom: '20px',
};

const cardGradeStyle: SxProps<Theme> = {
  fontFamily: 'inherit',
  fontSize: '11px',
  marginLeft: '4px',
  marginRight: '2px',
};

const cardViewStyle: SxProps<Theme> = {
  marginRight: '8px',
};

const cardViewIconStyle: SxProps<Theme> = {
  color: 'rgba(0, 0, 0, 0.6)',
};

const modalStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  border: 'none',
  borderRadius: '8px',
  outline: 'none',
  padding: '0px',
  width: '550px',
  height: 'auto',
  maxHeight: '90vh',
  '@media (max-width: 600px)': {
    width: '80%',
  },
};

const modalProfileIconStyle: SxProps<Theme> = {
  marginRight: '8px',
  borderRadius: '50%',
  width: '50px',
  height: '50px',
};

const modalPostTimingStyle: SxProps<Theme> = {
  fontSize: '12px',
};

const modalProfileContent: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '5px',
};

const modalStudentNameContent: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
};

const modalGradeName: SxProps<Theme> = {
  backgroundColor: 'rgba(165, 164, 244, 0.41)',
  borderRadius: '20px',
  fontWeight: 'bold',
  fontSize: '13px',
  padding: '6px 8px',
  marginRight: '5px',
  color: '#3C3077',
  border: '1px solid #3C3077',
};

const modalGradeContent: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '12px',
};

const modalSkillName: SxProps<Theme> = {
  backgroundColor: 'rgba(235, 102, 66, 0.31)',
  fontSize: '13px',
  borderRadius: '20px',
  fontWeight: 'bold',
  padding: '6px 8px',
  marginRight: '0px',
  color: '#EB6642',
  border: '1px solid #EB6642',
};

const modalMagazineNameStyle: SxProps<Theme> = {
  fontSize: '15px',
  color: '#000000',
  marginBottom: '7px',
};

const modalopenMagazineStyle: SxProps<Theme> = {
  border: '3px solid #3c3075',
  color: 'white',
  backgroundColor: '#3c3075',
  fontFamily: 'CormorantGaramondRegular',
  borderRadius: '4px',
  boxShadow: 'none',
  fontWeight: 'bold',
  fontSize: '10px',
  padding: '3px 6px',
  '&:hover': {
    backgroundColor: '#3c3075',
    color: 'white',
    boxShadow: 8,
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
};

const modalViewContainerStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '8px',
};

const cardMediaContainerStyle: SxProps<Theme> = {
  borderRadius: '8px',
};

const verticalBlurBoxStyle: SxProps<Theme> = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '40%',
  backgroundSize: 'cover',
  filter: 'blur(10px)',
  zIndex: 1,
};

type CommunityWallPagePropsType = {
  communityWallContent: any;
  setSkillFilter: (newSkill: string) => void;
  setGradeFilter: (newGrade: string) => void;
  updateViewCount: (
    tenantId: string,
    categoryId: string,
    userId: string,
    magazineId: string,
    activityId: string,
    questionId: string,
    views: number
  ) => void;
};

export const CommunityWallPage: React.FC<CommunityWallPagePropsType> = ({
  communityWallContent,
  setSkillFilter,
  setGradeFilter,
  updateViewCount,
}) => {
  const navigate = useNavigate();
  const [skill, setSkill] = React.useState('');
  const [grade, setGrade] = React.useState('');
  const [time, setTime] = React.useState('');
  const [dateInterval, setDateInterval] = React.useState({ startDate: '', endDate: '' });
  const [viewPostInModal, setViewPostInModal] = React.useState(false);
  const [selectedCard, setSelectedCard] = React.useState<any>(null);
  const [objectFitStyle, setObjectFitStyle] = React.useState<'contain' | 'cover'>('cover');
  const [isVerticalImage, setIsVerticalImage] = React.useState(false);
  const [viewCountTimer, setViewCountTimer] = useState<NodeJS.Timeout | null>(null);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const isLoggedIn = Boolean(localStorage.getItem('token'));
  const clickSound = new Audio(clickSoundFile);
  console.log(selectedCard);

  const skills = ['Critical Thinking', 'Problem Solving', 'Write', 'Read', 'Creativity thinking story', 'Creativity'];
  const grades = ['1', '2', '3', '4', '5', '6', '7', '8'];
  const timeFilters = ['Last Month', 'Last Week', 'Date Interval']; // New time filters
  const profileImage =
    'https://img.freepik.com/premium-vector/cute-baby-boy-profile-picture-kid-avatar_176411-4644.jpg';

  const handleSkillChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSkill(event.target.value);
    setSkillFilter(event.target.value);
  };

  const handleOpen = () => {
    setLoginModalOpen(true);
  };

  const handleLoginClose = () => {
    setLoginModalOpen(false);
  };

  const handleLoginButtonClick = () => {
    handleOpen();
  };

  const handleGradeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setGrade(event.target.value);
    setGradeFilter(event.target.value);
  };

  const handleTimeFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTime(event.target.value);
  };

  const handleDateIntervalChange = (startDate: string, endDate: string) => {
    setDateInterval({ startDate, endDate });
  };

  const handleViewPost = (card: any) => {
    playSound();
    const img = new Image();
    img.src = card.artImageURL;
    img.onload = () => {
      if (img.width < img.height) {
        setObjectFitStyle('contain');
        setIsVerticalImage(true);
      } else {
        setObjectFitStyle('cover');
        setIsVerticalImage(false);
      }
      setSelectedCard(card);
      setViewPostInModal(true);
      const timeout = setTimeout(() => {
        updateViewCount(
          card.tenantId,
          card.categoryId,
          card.userId,
          card.magazineId,
          card.activityId,
          card.questionId,
          card.views
        );
      }, 5000);
      setViewCountTimer(timeout);
    };
  };

  const handleClose = () => {
    setViewPostInModal(false);
    setSelectedCard(null);
    if (viewCountTimer) {
      clearTimeout(viewCountTimer);
      setViewCountTimer(null);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      handleLoginClose();
    }
  }, [isLoggedIn]);

  // Function to handle image load for card images
  const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const img = event.currentTarget;
    if (img.naturalWidth < img.naturalHeight) {
      img.style.objectFit = 'contain';
    } else {
      img.style.objectFit = 'cover';
    }
  };

  const openMagazineButton = (magazineId: string, activityId: string, activityName: string, magazineName: string) => {
    if (isLoggedIn) {
      navigate(
        {
          pathname: `/${ACTIVITY}`,
          search: `?id=${magazineId}&actid=${activityId}`,
        },
        {
          state: { key: activityName, magazineName: magazineName },
        }
      );
    } else {
      navigate('/magazines');
    }
  };

  const playSound = () => {
    clickSound.play();
  };

  const yellowOwlHeader = {
    img: yellowOwlLogo,
    title: yellowOwlTitle,
  };

  const formatDateString = (dateString: string): string => {
    const date = new Date(dateString);
    const now = new Date();
    const diffTime = now.getTime() - date.getTime();
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffYears = now.getFullYear() - date.getFullYear();

    const getTimeString = (date: Date): string => {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    };

    const timeString = getTimeString(date);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const formattedMonth = monthNames[date.getMonth()];
    const formattedDate = date.getDate();

    if (diffDays === 0) {
      return `Today ${timeString}`;
    } else if (diffDays === 1) {
      return `Yesterday ${timeString}`;
    } else if (diffDays <= 7) {
      return `${diffDays} days ago ${timeString}`;
    } else if (diffYears >= 1) {
      const formattedYear = date.getFullYear();
      return `${formattedMonth} ${formattedDate}, ${formattedYear} ${timeString}`;
    } else {
      return `${formattedMonth} ${formattedDate} ${timeString}`;
    }
  };

  // Generate random animation properties for each card
  const generateRandomAnimation = () => {
    const random = (min: number, max: number) => `${Math.random() * (max - min) + min}px`;
    return {
      x: [random(-10, 10), random(-10, 10), random(-10, 10), random(-10, 10)],
      y: [random(-10, 10), random(-10, 10), random(-10, 10), random(-10, 10)],
    };
  };

  const filteredCommunityWallContent = communityWallContent.filter((card: any) => {
    const matchesSkill = skill === '' || skill === 'no-filter' || card.skill == skill;
    const matchesGrade = grade === '' || grade === 'no-filter' || card.grade == grade;

    let matchesTime = true;
    if (time && time !== 'no-filter') {
      const postDate = new Date(card.postDateTime);
      const now = new Date();

      switch (time) {
        case 'Last Month': {
          const lastMonth = new Date();
          lastMonth.setMonth(now.getMonth() - 1);
          matchesTime = postDate >= lastMonth && postDate <= now;
          break;
        }
        case 'Last Week': {
          const lastWeek = new Date();
          lastWeek.setDate(now.getDate() - 7);
          matchesTime = postDate >= lastWeek && postDate <= now;
          break;
        }
        case 'Date Interval': {
          const startDate = dateInterval.startDate ? new Date(dateInterval.startDate) : null;
          const endDate = dateInterval.endDate ? new Date(dateInterval.endDate) : null;
          if (startDate && endDate) {
            matchesTime = postDate >= startDate && postDate <= endDate;
          } else if (startDate) {
            matchesTime = postDate >= startDate;
          } else if (endDate) {
            matchesTime = postDate <= endDate;
          } else {
            matchesTime = false;
          }
          break;
        }
        default:
          matchesTime = true;
      }
    }
    return matchesSkill && matchesGrade && matchesTime;
  });

  return (
    <Box sx={containerStyle}>
      <AppBar position='sticky' sx={appBarStyle}>
        <Toolbar sx={toolbarStyle}>
          <Box sx={toolbarLogoStyle}>
            <Box component='img' src={yellowOwlHeader.img} alt='yellowOwlLogo' sx={yellowOwlLogoBorderBox} />
            <Box component='img' src={yellowOwlHeader.title} alt='yellowOwlLogo' sx={titleStyle} />
          </Box>
          <Box>
            {isLoggedIn ? (
              <Box>
                <LogoutComponent />
              </Box>
            ) : (
              <Button sx={loginButtonStyle} onClick={handleLoginButtonClick}>
                Login
                <LoginIcon sx={{ ml: 1 }} />
              </Button>
            )}
            <CommunityWallLoginContainer open={loginModalOpen} onClose={handleLoginClose} />
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={pageContainerStyle}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <Typography variant='h4' component='div' sx={headerTextStyle}>
              Community Wall
            </Typography>
          </Grid>
          <Grid item xs />
          <Grid item>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div style={dropdownContainer}>
                  <label style={dropdownLabel}>Skill</label>
                  <div style={dropdownSelectContainer}>
                    <select value={skill} onChange={handleSkillChange} style={dropdownSelectCommon}>
                      <option key='no-filter' value='no-filter'>
                        All
                      </option>
                      {skills.map((skill) => (
                        <option key={skill} value={skill}>
                          {skill}
                        </option>
                      ))}
                    </select>
                    <span style={dropdownArrow}>▼</span>
                  </div>
                </div>
                <div style={dropdownContainer}>
                  <label style={dropdownLabel}>Grade</label>
                  <div style={dropdownSelectContainer}>
                    <select value={grade} onChange={handleGradeChange} style={dropdownSelectCommon}>
                      <option key='no-filter' value='no-filter'>
                        All
                      </option>
                      {grades.map((grade) => (
                        <option key={grade} value={grade}>
                          {grade}
                        </option>
                      ))}
                    </select>
                    <span style={dropdownArrow}>▼</span>
                  </div>
                </div>
                <div style={dropdownContainer}>
                  <label style={dropdownLabel}>Time</label>
                  <div style={dropdownSelectContainer}>
                    <select value={time} onChange={handleTimeFilterChange} style={dropdownSelectCommon}>
                      <option key='no-filter' value='no-filter'>
                        All
                      </option>
                      {timeFilters.map((filter) => (
                        <option key={filter} value={filter}>
                          {filter}
                        </option>
                      ))}
                    </select>
                    <span style={dropdownArrow}>▼</span>
                  </div>
                </div>
              </div>
              {time === 'Date Interval' && (
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                  <div style={dropdownContainer}>
                    <label style={{ marginRight: '4px' }}>From</label>
                    <input
                      type='date'
                      style={dateIntervalStyle}
                      value={dateInterval.startDate}
                      onChange={(e) => handleDateIntervalChange(e.target.value, dateInterval.endDate)}
                    />
                  </div>
                  <div style={dropdownContainer}>
                    <label style={{ marginRight: '4px' }}>To</label>
                    <input
                      type='date'
                      style={dateIntervalStyle}
                      value={dateInterval.endDate}
                      onChange={(e) => handleDateIntervalChange(dateInterval.startDate, e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        {filteredCommunityWallContent && filteredCommunityWallContent.length > 0 ? (
          <Grid container spacing={2} sx={cardsGridStyle}>
            {filteredCommunityWallContent.map((card: any, index: number) => (
              <Grid item xs={12} sm={6} md={2.9} key={index}>
                <AnimatedCard
                  animate={generateRandomAnimation()}
                  transition={{ duration: 3, ease: 'easeInOut', repeat: Infinity, repeatType: 'mirror' }}
                  onClick={() => handleViewPost(card)}
                >
                  <Box sx={modalProfileContent}>
                    <CardMedia
                      component='img'
                      alt={card.userName}
                      image={card.artImageURL}
                      sx={cardMediaStyle}
                      onLoad={handleImageLoad}
                    />
                  </Box>
                  <CardContent sx={cardContentStyle}>
                    <Box display='flex' alignItems='center'>
                      <Box sx={cardMediaContainerStyle}>
                        <CardMedia
                          component='img'
                          alt={card.userName}
                          image={card.profileImg || profileImage}
                          sx={profileIconStyle}
                        />
                      </Box>
                      <Box sx={cardStudentNameContent}>
                        <Typography variant='body2' component='p' sx={cardStudentName}>
                          {card.userName}
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                          {card.grade}
                        </Typography>
                      </Box>
                      <Box sx={gradeContainerStyle}>
                        <Typography variant='body2' color='white' sx={cardGradeStyle}>
                          {card.rewardsPoints}
                        </Typography>
                        <CardMedia component='img' alt='grade icon' image={cardMedal} sx={gradeIconStyle} />
                      </Box>
                    </Box>
                    <Box sx={cardMagazineStyle} mt={1}>
                      <Typography variant='body2' color='text.secondary' sx={magazineNameStyle}>
                        {card.magazineName}
                      </Typography>
                      <Box display='flex' alignItems='center'>
                        <Typography variant='body2' color='text.secondary' sx={cardViewStyle}>
                          {card.views}
                        </Typography>
                        <VisibilityIcon sx={cardViewIconStyle} />
                      </Box>
                    </Box>
                  </CardContent>
                </AnimatedCard>
              </Grid>
            ))}
          </Grid>
        ) : (
          <NoResults />
        )}
      </Box>
      <Modal open={viewPostInModal} onClose={handleClose}>
        <Box sx={modalStyle}>
          {selectedCard && (
            <>
              <CardContent>
                <Box sx={modalProfileContent}>
                  <CardMedia
                    component='img'
                    alt={selectedCard.userName}
                    image={selectedCard.profileImg || profileImage}
                    sx={modalProfileIconStyle}
                  />
                  <Box sx={modalStudentNameContent}>
                    <Typography variant='body2' component='p' sx={cardStudentName}>
                      {selectedCard.userName}
                    </Typography>
                    <Typography variant='body2' color='text.secondary' sx={modalPostTimingStyle}>
                      {formatDateString(selectedCard.postDateTime)}
                    </Typography>
                  </Box>
                  <Box sx={gradeContainerStyle}>
                    <Typography variant='body2' color='white' sx={cardGradeStyle}>
                      {selectedCard.rewardsPoints}
                    </Typography>
                    <CardMedia component='img' alt='grade icon' image={cardMedal} sx={gradeIconStyle} />
                  </Box>
                </Box>
                <Typography variant='body2' color='text.secondary' sx={modalMagazineNameStyle}>
                  {selectedCard.magazineName}
                </Typography>
                <Box sx={modalGradeContent}>
                  <Typography variant='body2' sx={modalGradeName}>
                    {selectedCard.grade}
                  </Typography>
                  <Typography variant='body2' sx={modalSkillName}>
                    {selectedCard.skill}
                  </Typography>
                </Box>
                <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {isVerticalImage && (
                    <>
                      {/* Left blurred side */}
                      <Box
                        sx={{
                          ...verticalBlurBoxStyle,
                          left: 0,
                          backgroundImage: `url(${selectedCard.artImageURL})`,
                        }}
                      />
                      {/* Right blurred side */}
                      <Box
                        sx={{
                          ...verticalBlurBoxStyle,
                          right: 0,
                          backgroundImage: `url(${selectedCard.artImageURL})`,
                        }}
                      />
                    </>
                  )}
                  <CardMedia
                    component='img'
                    alt={selectedCard.userName}
                    image={selectedCard.artImageURL}
                    sx={{ ...cardMediaStyle, objectFit: objectFitStyle, zIndex: 2 }}
                  />
                </Box>
                <Box sx={modalViewContainerStyle}>
                  <Box display='flex' alignItems='center'>
                    <Typography variant='body2' color='text.secondary' sx={cardViewStyle}>
                      {selectedCard.views}
                    </Typography>
                    <VisibilityIcon sx={cardViewIconStyle} />
                  </Box>
                  <Button
                    sx={modalopenMagazineStyle}
                    onClick={() =>
                      openMagazineButton(
                        selectedCard.magazineId,
                        selectedCard.activityId,
                        selectedCard.activityName,
                        selectedCard.magazineName
                      )
                    }
                  >
                    open magazine
                  </Button>
                </Box>
              </CardContent>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};
