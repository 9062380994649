import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Theme, Button, styled, IconButton } from '@mui/material';
import { SxProps } from '@mui/system';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ReactS3Client from 'react-aws-s3-typescript';
import { InputContent, ActivitySkillType, QuestionFormat } from '@yellow-owl/client-sdk';
import { YellowOwlContext } from '../../../context/YellowOwlContext';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ConfirmationModal from '../confirmation-modal/confirmation-modal';
import DeleteIcon from '@mui/icons-material/Delete';

// const videoPlayerBoxStyle: SxProps<Theme> = {
//   position: 'relative',
//   marginTop: '1vh',
//   height: 'auto',
//   minHeight: '225px',
//   margin: '10px auto',
//   width: '400px',
//   borderRadius: '5px',
//   border: '4px solid #673AB7',
//   outline: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
//   backgroundColor: '#262626',
// };

const countDownBoxStyle: SxProps<Theme> = {
  background: '#a699bd26',
  width: '100%',
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  '& h1': {
    color: '#fff',
    position: 'absolute',
    top: '25%',
    left: '50%',
    transform: 'translate(-250%, -50%)',
    animation: 'countDownAnimation 1s ease-in-out infinite',
  },
  '@keyframes countDownAnimation': {
    '0%': {
      transform: 'scale(1)',
      opacity: '1',
    },
    '100%': {
      transform: 'scale(8)',
      opacity: '0',
    },
  },
};

const recNofityBox: SxProps<Theme> = {
  position: 'absolute',
  top: '10px',
  left: '10px',
  background: '#0000005c',
  height: '30px',
  width: '65px',
  borderRadius: '4px',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& p': {
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem !important',
    fontFamily: 'RalewayRegular !important',
    letterSpacing: '1px',
  },
  '& span': {
    width: '10px',
    height: '10px',
    backgroundColor: 'Red',
    borderRadius: '50%',
    display: 'inline-block',
    animation: 'blink 1s infinite',
  },
  '@keyframes blink': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
};

const deleteVideoBtnStyle: SxProps<Theme> = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: '10px',
  right: '10px',
  background: '#0000005c',
  height: '40px',
  width: '40px',
  borderRadius: '4px',
  color: 'white',
  boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET!,
  region: process.env.REACT_APP_S3_REGION!,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY!,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!,
};

type MagazineVideoInputProps = {
  questionFormat: QuestionFormat;
  inputContent: Array<InputContent>;
  url: string;
  magazineId: string;
  userId: string;
  activityId: string;
  tenantId: number;
  categoryId: number;
  skill: Array<ActivitySkillType>;
  questionId: number;
  questionFormatId: number;
  globalInputState: boolean;
  onUpdateInputContents: (input: Array<InputContent>) => boolean;
};

const mimeType = 'video/webm; codecs="opus,vp8"';

export function MagazineVideoInput(props: MagazineVideoInputProps) {
  const {
    inputContent,
    questionFormat,
    url,
    userId,
    magazineId,
    activityId,
    tenantId,
    categoryId,
    skill,
    questionId,
    questionFormatId,
    globalInputState,
    onUpdateInputContents,
  } = props;
  const s3 = new ReactS3Client(config);
  const [seconds, setSeconds] = useState(0);
  const [videoUrl, setVideoUrl] = useState<string | null>(url || null);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const yellowOwlApi = useContext(YellowOwlContext)!;
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    if (url) {
      setVideoUrl(url);
    }
  }, [url]);

  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [seconds]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setVideoUrl('');
      await uploadVideo(file);
    }
  };

  const uploadVideo = async (file: File) => {
    try {
      const data = await s3.uploadFile(file);
      if (data && data.location) {
        setVideoUrl(data.location);
        saveVideoResponse(data.location);
      }
    } catch (exception) {
      console.error('Error saving video response:', exception);
    }
  };

  const saveVideoResponse = async (url: string) => {
    try {
      inputContent.forEach((inputElt: InputContent) => {
        inputElt.questionData.forEach((questionData) => {
          if (questionData.id === questionId) {
            questionData.questionFormat.forEach((questionFormat) => {
              if (questionFormat.format.id === questionFormatId) {
                questionFormat.format.response = [url];
              }
            });
          }
        });
        if (questionFormat.format.mandatory) {
          setIsValid(url.trim().length > 0);
        } else {
          setIsValid(true);
        }
      });
      const result = await yellowOwlApi.addUserResponse({
        userId: +userId,
        magazineId: +magazineId,
        activityId: activityId,
        inputContent: inputContent,
        tenantId: tenantId,
        categoryId: categoryId,
        skill: skill,
      });
      if (result) {
        /* notification */
        onUpdateInputContents(inputContent);
      }
    } catch (e) {
      /**
       * Need to show notification accordingly
       */
    }
  };

  const startRecording = async () => {
    setVideoUrl('');
    const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    streamRef.current = stream;
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
    }

    const recorder = new MediaRecorder(stream, { mimeType });
    const localRecordedChunks: Blob[] = [];

    recorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        localRecordedChunks.push(event.data);
      }
    };

    recorder.onstop = async () => {
      const blob = new Blob(localRecordedChunks, { type: 'video/webm' });
      const file = new File([blob], 'recorded-video.webm', { type: 'video/webm' });
      await uploadVideo(file);
    };

    recorder.start();
    setMediaRecorder(recorder);
    setIsRecording(true);
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
    setIsRecording(false);
  };

  const handleRecordButtonClick = () => {
    setVideoUrl('');
    setSeconds(5);
    setTimeout(() => {
      startRecording();
    }, 5000);
  };

  const handleDelete = () => {
    setOpenConfirmationModal(true);
  };

  const handleConfirmationModalClose = async (userResponse: boolean) => {
    setOpenConfirmationModal(false);
    if (userResponse) {
      try {
        const filePath = config.bucketName + '/' + videoUrl?.split('.com/')[1];
        await s3.deleteFile(filePath!);
        setVideoUrl('');
        await saveVideoResponse('');
      } catch (e) {
        /**
         * Need to show notification accordingly
         */
      }
    }
  };

  useEffect(() => {
    if (globalInputState) {
      let isValid = true; // Start by assuming the form is valid

      inputContent.forEach((inputElt: InputContent) => {
        inputElt.questionData.forEach((questionData) => {
          if (questionData.id === questionId) {
            questionData.questionFormat.forEach((questionFormat) => {
              if (questionFormat.format.id === questionFormatId) {
                // Check if the question format is mandatory
                if (questionFormat.format.mandatory) {
                  // Check if response is non-empty
                  const isValidResponse = Boolean(
                    questionFormat.format.response && questionFormat.format.response[0]?.trim() !== ''
                  );

                  isValid = isValid && isValidResponse; // Update isValid
                }
              }
            });
          }
        });
      });

      if (questionFormat.format.mandatory) {
        setIsValid(isValid);
      } else {
        setIsValid(true);
      }
    }
  }, [globalInputState, inputContent, questionId, questionFormatId]);

  const videoPlayerBoxStyle: SxProps<Theme> = {
    position: 'relative',
    marginTop: '1vh',
    height: 'auto',
    minHeight: '225px',
    margin: '10px auto',
    width: '400px',
    borderRadius: '5px',
    border: isValid ? '4px solid #673AB7' : '4px solid red',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    backgroundColor: '#262626',
  };

  return (
    <>
      <Box sx={videoPlayerBoxStyle} flexDirection={'column'}>
        <video ref={videoRef} autoPlay muted width={!videoUrl ? '400' : '0'}></video>
        {videoUrl && !isRecording && (
          <video controls width='400'>
            <source src={videoUrl} type='video/webm' />
          </video>
        )}
        {seconds != 0 && (
          <Box sx={countDownBoxStyle}>
            <h1 className='videoStartCountdown'>{seconds}</h1>
          </Box>
        )}
        {isRecording && (
          <Box sx={recNofityBox}>
            <p>
              <span></span>&nbsp;Rec
            </p>
          </Box>
        )}
        {videoUrl && !isRecording && (
          <Box sx={deleteVideoBtnStyle}>
            <IconButton onClick={handleDelete}>
              <DeleteIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Box>
        )}
      </Box>
      {!videoUrl && (
        <Box display={'flex'} justifyContent={'space-evenly'}>
          <Button
            component='label'
            variant='contained'
            tabIndex={-1}
            startIcon={<FileUploadIcon />}
            disabled={isRecording}
          >
            Upload Video
            <VisuallyHiddenInput type='file' accept='video/*' onChange={handleFileChange} />
          </Button>

          {isRecording ? (
            <Button variant='contained' onClick={stopRecording}>
              Stop Recording
            </Button>
          ) : (
            <Button
              component='label'
              variant='contained'
              tabIndex={-1}
              startIcon={<CameraAltIcon />}
              onClick={handleRecordButtonClick}
              disabled={isRecording}
            >
              Record Video
            </Button>
          )}
        </Box>
      )}
      <ConfirmationModal
        title='Delete Video'
        content='Are you sure you want to delete this video?'
        open={openConfirmationModal}
        onClose={handleConfirmationModalClose}
      />
    </>
  );
}
