import React, { useContext, useState } from 'react';
import { CommunityWallLogin } from '../../components/community-wall/CommunityWallLoginPage';
import { toast } from 'react-toastify';
import { YellowOwlContext } from '../../context/YellowOwlContext';
import { COUNTRY_CODE_KEY, PHONE_NUMBER_KEY, TOKEN_KEY, useLocalStorage } from '../../context/LocalStorageContext';
import { getCodeForCountry } from '../../utils/commonUtils';

const isValidPhoneNumber = (phoneNumber: string): boolean => {
  if (phoneNumber === '' || phoneNumber.length < 10) return false;
  return true;
};

export type CommunityWallLoginContainerProps = {
  open: boolean;
  onClose: () => void;
};

export const CommunityWallLoginContainer: React.FC<CommunityWallLoginContainerProps> = ({ open, onClose }) => {
  const yellowOwlApi = useContext(YellowOwlContext)!;
  const { state, updateLocalStorage } = useLocalStorage();

  const canSignInResult = isValidPhoneNumber(state.phoneNumber ?? '');

  const [canSignIn, setCanSignIn] = useState(canSignInResult);

  const handlePhoneNumberChange = (number: string) => {
    updateLocalStorage(PHONE_NUMBER_KEY, number);
    if (isValidPhoneNumber(number)) {
      setCanSignIn(true);
    } else {
      setCanSignIn(false);
    }
  };

  const handleCountryCodeChange = (countryCodeText: string) => {
    updateLocalStorage(COUNTRY_CODE_KEY, countryCodeText);
  };

  const handleRequestSignIn = async () => {
    if (state.phoneNumber) {
      if (state.phoneNumber === '' || state.phoneNumber.length < 10) return;
    } else {
      return;
    }

    try {
      const countryCodeNumber = getCodeForCountry(state.countryCode ?? 'IN');
      const parentPhoneNumber = `${countryCodeNumber}${state.phoneNumber}`;
      const validUser = await yellowOwlApi.checkIfValidUser({ parentPhoneNumber });
      if (validUser.data.message === 'Valid user') {
        updateLocalStorage(TOKEN_KEY, 'sample-token');
        updateLocalStorage(COUNTRY_CODE_KEY, state.countryCode);
      }
    } catch (e) {
      toast.error('Unauthorized user');
    }
  };

  return (
    <CommunityWallLogin
      phoneNumber={state.phoneNumber ?? ''}
      countryCode={state.countryCode ?? 'IN'}
      canSignIn={canSignIn}
      onCountryCodeChange={handleCountryCodeChange}
      onPhoneNumberChange={handlePhoneNumberChange}
      onRequestSignIn={handleRequestSignIn}
      open={open}
      onClose={onClose}
    />
  );
};
