// /* eslint-disable */
import { Box, Button, Grid, IconButton, Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import curevLine from '../../assets/icons/curve-line.svg';
import laptop from '../../assets/icons/laptop.svg';
import rocket from '../../assets/icons/rocket.svg';
import yellowOwlLogo from '../../assets/icons/owl-sidepanel.svg';
// import exploreMagazineBackground from '../../assets/images/explore-magazine-background.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import noMagazinesBackgroundImage from '../../assets/images/no-magazines-background.svg';
import { yellowOwlMessages } from '../../Constants';
import { useNavigate } from 'react-router-dom';
import owlHeader from '../../assets/icons/owl-header.svg';
import { Magazine } from '@yellow-owl/client-sdk';
import { LogoutComponent } from '../common/LogoutComponent';
import BookSelfImage from '../../assets/images/self-image.png';
import { useEffect, useRef, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import communityWallButton from '../../assets/images/community-wall-button.png';

const magazinesBackground: SxProps<Theme> = {
  background: `url(${curevLine}), linear-gradient(to right,#3C3077 0%,#3C3077 25%, #FBEC53 25%, #FBEC53 100%)`,
  height: '100vh',
};

const noMagazinesBackground: SxProps<Theme> = {
  background: '#FBEC53',
  height: '100vh',
};

const leftPaneHeader: SxProps<Theme> = {
  fontFamily: 'MacondoRegular',
  fontWeight: '400',
  color: '#FFFFFF',
  marginTop: '4vh',
};

const commonContent: SxProps<Theme> = {
  height: '90vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontFamily: 'CormorantGaramondRegular, sans-serif',
  lineHeight: '72px',
  fontStyle: 'normal',
  fontWeight: 700,
  color: '#FFFFFF',
  transform: 'translateY(0%)',
};

const yellowOwlLogoBorderBox: SxProps<Theme> = {
  img: {
    width: '10vw',
    height: '10vw',
  },
  display: 'flex',
  width: '10vw',
  height: '10vw',
  background: 'linear-gradient(#3C3077, #3C3077) padding-box, linear-gradient(to top, #6649D8, #FBEC53) border-box',
  borderRadius: '50em',
  border: '19px solid transparent',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '2vh',
  marginTop: '4vh',
};

const laptopIcon: SxProps<Theme> = {
  img: {
    width: '5vw',
  },
  position: 'absolute',
  top: '2vh',
  left: '0.5vw',
};

const rocketIcon: SxProps<Theme> = {
  img: {
    width: '5vw',
  },
  position: 'absolute',
  bottom: 0,
  left: '3vw',
};

const CommunityWallButton: SxProps<Theme> = {
  img: {
    width: '5vw',
  },
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  bottom: '6vw',
  flexDirection: 'column',
};

const CommunityWallButtonText: SxProps<Theme> = {
  mt: 1,
  fontSize: '14px',
  fontFamily: 'CormorantGaramondRegular',
  color: '#FFFFFF',
};

const downIcon: SxProps<Theme> = {
  fontSize: '2vh',
  fontFamily: 'RalewayRegular',
  borderRadius: '100%',
  height: '6.5vh',
  width: '6.5vh',
};
const upIcon: SxProps<Theme> = {
  marginTop: '4vh',
  fontSize: '2vh',
  fontFamily: 'RalewayRegular',
  borderRadius: '100%',
  height: '6.5vh',
  width: '6.5vh',
};

const emptyDiv: SxProps<Theme> = {
  marginTop: '4vh',
  height: '6.5vh',
  width: '6.5vh',
};

const noMagazinesColumn: SxProps<Theme> = {
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const logoutContainer: SxProps<Theme> = {
  width: '100%',
  height: '80px',
  maxHeight: '10vh',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
};

const headerBox: SxProps<Theme> = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const yellowText: SxProps<Theme> = {
  fontFamily: 'MacondoRegular',
  fontWeight: '400',
  fontSize: '5vh',
  lineHeight: '16px',
  fontStyle: 'normal',
  color: '#3C3077',
};

const owlText: SxProps<Theme> = {
  fontFamily: 'MacondoRegular',
  fontWeight: '400',
  fontSize: '5vh',
  lineHeight: '16px',
  fontStyle: 'normal',
  color: '#3C3077',
  marginLeft: '14px',
};
const imgStyle = { width: '5vw', marginLeft: '14px' };

const selfsContainer: SxProps<Theme> = {
  height: '90vh',
  overflowX: 'hidden',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

const scrollBtnStyle: SxProps<Theme> = {
  position: 'absolute',
  width: '45px',
  height: '45px',
  bottom: '10px',
  right: '20px',
  cursor: 'pointer',
  backgroundColor: '#3b3077',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10%',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
  zIndex: '1',
  transition: 'bottom 0.3s ease-in-out',
  '&:hover': {
    bottom: '15px',
  },
  // animation: 'bounce 5s ease infinite',
};

const scrollIcon: SxProps<Theme> = {
  color: '#fbec54',
  fontSize: '4.5vh',
};

const allCenterFlex: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export type ExploreCoursesPageComponentProps = {
  magazines: Magazine[];
  isFirst: boolean;
  isLast: boolean;
  onClickScrollDown(): void;
  onClickScrollUp(): void;
};

export const ExploreMagazinesPage: React.FC<ExploreCoursesPageComponentProps> = ({
  magazines,
  isFirst,
  isLast,
  onClickScrollUp,
  onClickScrollDown,
}) => {
  const navigate = useNavigate();
  const onMagazineClick = (magazine: Magazine) =>
    navigate({
      pathname: '/magazine/cover',
      search: `?id=${magazine.id}&coverPicUrl=${magazine.coverPicUrl}&name=${encodeURIComponent(magazine.title)}`,
    });
  const bookShelfContainerRef = useRef<HTMLDivElement | null>(null);
  const [showScrollBtn, setShowScrollBtn] = useState(false);

  useEffect(() => {
    const listenResize = new ResizeObserver(updateScrollButtons);
    if (bookShelfContainerRef.current) {
      listenResize.observe(bookShelfContainerRef.current);
    }

    return () => {
      if (bookShelfContainerRef.current) {
        listenResize.unobserve(bookShelfContainerRef.current);
      }
    };
  }, []);

  const updateScrollButtons = () => {
    if (bookShelfContainerRef.current) {
      setShowScrollBtn(bookShelfContainerRef.current.scrollHeight > bookShelfContainerRef.current.clientHeight);
    }
  };

  const handleScrollDown = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      const newScrollTop = ref.current.scrollTop + ref.current.clientHeight / 2;
      ref.current.scrollTo({
        top: newScrollTop,
        behavior: 'smooth',
      });
    }
  };

  const getshelfItems = (
    <Stack ref={bookShelfContainerRef} alignItems={'center'} sx={selfsContainer}>
      {!isFirst && (
        <Button variant='contained' sx={upIcon} onClick={onClickScrollUp}>
          <ExpandLessIcon />
        </Button>
      )}
      <div className='yow-book-self-wrapper'>
        <div className='yow-book-list'>
          <div className='row'>
            <Grid container width={'60vw'} marginTop={'2vh'}>
              {magazines.map(
                (magazine, index) =>
                  index < 3 && (
                    <Grid textAlign={'center'} key={index} item xs={4}>
                      <div className='mt-2 yow-book-card'>
                        <img
                          src={magazine.coverPicUrl}
                          className='yow-lib-book'
                          onClick={() => onMagazineClick(magazine)}
                          alt={`Book ${index + 1}`}
                        />
                        <div className='card mt-1 text-center bg-yellow br-card p-2 mt-2'>
                          <div className='book-info'>
                            <p className='m-0'>{magazine.title}</p>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  )
              )}
            </Grid>
          </div>
        </div>
        {magazines.length >= 1 && (
          <div className='yow-self'>
            <img src={BookSelfImage} className='w-100' alt='Self Image' />
          </div>
        )}
      </div>
      <div className='yow-book-self-wrapper'>
        <div className='yow-book-list'>
          <div className='row'>
            <Grid container width={'60vw'} marginTop={'2vh'}>
              {magazines.map(
                (magazine, index) =>
                  index >= 3 &&
                  index <= 5 && (
                    <Grid textAlign={'center'} key={index} item xs={4}>
                      <div className='mt-2 yow-book-card'>
                        <img
                          src={magazine.coverPicUrl}
                          className='yow-lib-book'
                          onClick={() => onMagazineClick(magazine)}
                          alt={`Book ${index + 1}`}
                        />
                        <div className='card mt-1 text-center bg-yellow br-card p-2 mt-2'>
                          <div className='book-info'>
                            <p className='m-0'>{magazine.title}</p>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  )
              )}
            </Grid>
          </div>
        </div>
        {magazines.length > 3 && (
          <div className='yow-self'>
            <img src={BookSelfImage} className='w-100' alt='Self Image' />
          </div>
        )}
      </div>
      <div className='yow-book-self-wrapper'>
        <div className='yow-book-list'>
          <div className='row'>
            <Grid container width={'60vw'} marginTop={'2vh'}>
              {magazines.map(
                (magazine, index) =>
                  index >= 6 &&
                  index <= 8 && (
                    <Grid textAlign={'center'} key={index} item xs={4}>
                      <div className='mt-2 yow-book-card'>
                        <img
                          src={magazine.coverPicUrl}
                          className='yow-lib-book'
                          onClick={() => onMagazineClick(magazine)}
                          alt={`Book ${index + 1}`}
                        />
                        <div className='card mt-1 text-center bg-yellow br-card p-2 mt-2'>
                          <div className='book-info'>
                            <p className='m-0'>{magazine.title}</p>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  )
              )}
            </Grid>
          </div>
        </div>
        {magazines.length > 6 && (
          <div className='yow-self'>
            <img src={BookSelfImage} className='w-100' alt='Self Image' />
          </div>
        )}
      </div>
      {!isLast && (
        <Button variant='contained' sx={downIcon} onClick={onClickScrollDown}>
          <ExpandMoreIcon />
        </Button>
      )}
      <Box sx={emptyDiv}></Box>
    </Stack>
  );

  useEffect(() => {
    setTimeout(() => {
      updateScrollButtons();
    }, 100);
  }, [getshelfItems]);

  const handleCommunityWallButtonClick = () => {
    navigate('/community-wall');
  };

  return (
    <Box>
      {/* TODO: show logout button also when there are no magazines */}
      {magazines.length == 0 && (
        <>
          <Grid container sx={noMagazinesBackground}>
            <Grid item xs={11} sx={allCenterFlex}>
              <Box sx={headerBox}>
                <Typography sx={yellowText} variant='body2'>
                  Yellow
                </Typography>
                <img src={owlHeader} style={imgStyle} alt='yellow-owl-header' />
                <Typography sx={owlText} variant='body2'>
                  Owl
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1} sx={allCenterFlex}>
              <Box mr='20px'>
                <LogoutComponent />
              </Box>
            </Grid>
            <Grid sx={noMagazinesColumn} item xs={6}>
              <img src={noMagazinesBackgroundImage} alt='noMagazinesBackground' />
            </Grid>
            <Grid sx={noMagazinesColumn} item xs={6}>
              <Stack>
                <Typography align={'center'} gutterBottom color={'#3C3077'} fontWeight={'600'} variant={'h3'}>
                  {yellowOwlMessages.NO_ISSUES_AVAILABLE}
                </Typography>
                {/* <Typography align={'center'} variant={'h4'} color={'#3C3077'} fontWeight={'600'}>
                  {yellowOwlMessages.NEXT_ISSUE_AVAILABLE_ON}
                </Typography> */}
                {/* <Typography align={'center'} variant={'h4'} color={'#3C3077'} fontWeight={'600'}>jan 2023</Typography> */}
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
      {magazines.length > 0 && (
        <Grid container sx={magazinesBackground}>
          <Grid item xs={3}>
            <Typography align={'center'} sx={leftPaneHeader} variant={'h5'}>
              Welcome to the library!
            </Typography>
            <Box sx={commonContent}>
              <Box sx={laptopIcon}>
                <img src={laptop} alt='laptop' />
              </Box>
              <Box sx={rocketIcon}>
                <img src={rocket} alt='rocket' />
              </Box>
              <Box sx={yellowOwlLogoBorderBox}>
                <img src={yellowOwlLogo} alt='yellowOwlLogo' />
              </Box>
              <Typography align={'center'} variant={'h6'}>
                We are happy to have
                <br />
                you here!
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button sx={CommunityWallButton} onClick={handleCommunityWallButtonClick}>
                <img
                  src={communityWallButton}
                  alt='Community Wall Button'
                  style={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '15px',
                  }}
                />
                <Box component='span' sx={CommunityWallButtonText}>
                  Community Wall
                </Box>
              </Button>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box>
              <Box sx={logoutContainer}>
                {/* <img src={exploreMagazineBackground} alt='explore magazines background' /> */}
                <Box mr='20px'>
                  <LogoutComponent />
                </Box>
              </Box>
              <Box display={'flex'} justifyContent={'center'}>
                {getshelfItems}
              </Box>
            </Box>
            {showScrollBtn && (
              <Box sx={scrollBtnStyle}>
                <IconButton onClick={() => handleScrollDown(bookShelfContainerRef)}>
                  <KeyboardArrowDownIcon sx={scrollIcon} />
                </IconButton>
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
