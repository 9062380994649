import { useState, useEffect, useContext, useRef } from 'react';
import { Box, IconButton, Theme, Tooltip } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import { SxProps } from '@mui/system';
import ReactS3Client from 'react-aws-s3-typescript';
import { YellowOwlContext } from '../../../context/YellowOwlContext';
import { ActivitySkillType, InputContent, QuestionFormat } from '@yellow-owl/client-sdk';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ConfirmationModal from '../confirmation-modal/confirmation-modal';
import DeleteIcon from '@mui/icons-material/Delete';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import SoundAnimation from '../../../assets/gif/sound-animated.gif';
import SoundBlack from '../../../assets/images/sound-black.png';
import SoundGray from '../../../assets/images/sound-gray.png';

const voiceInputContainerStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  width: '500px',
  margin: '0 auto',
};

const micBtnStyle: SxProps<Theme> = {
  backgroundColor: '#A18EFF',
  borderRadius: '3px',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#7a5aff',
  },
};

const audioPlayBtnStyle: SxProps<Theme> = {
  backgroundColor: '#00000000',
  borderRadius: '3px',
  color: '#673ab7',
};

type MagazineVoiceInputProps = {
  inputContent: Array<InputContent>;
  url: string;
  magazineId: string;
  userId: string;
  questionFormatId: number;
  activityId: string;
  tenantId: number;
  categoryId: number;
  skill: Array<ActivitySkillType>;
  questionId: number;
  questionFormat: QuestionFormat;
  globalInputState: boolean;
  onUpdateInputContents: (input: Array<InputContent>) => boolean;
};

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET!,
  region: process.env.REACT_APP_S3_REGION!,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY!,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!,
};

export function MagazineVoiceInput(props: MagazineVoiceInputProps) {
  const {
    questionFormat,
    inputContent,
    url,
    userId,
    magazineId,
    activityId,
    tenantId,
    categoryId,
    skill,
    questionId,
    questionFormatId,
    onUpdateInputContents,
    globalInputState,
  } = props;
  const s3 = new ReactS3Client(config);
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);
  const yellowOwlApi = useContext(YellowOwlContext)!;
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [showRecordBtn, setShowRecordBtn] = useState(true);
  const [recordingTimeout, setRecordingTimeout] = useState<NodeJS.Timeout | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [isValid, setIsValid] = useState<boolean>(true);
  useEffect(() => {
    const validateAndSetAudio = async () => {
      if (url) {
        setAudioURL(url);
        audioPlayerRef.current = new Audio(url);
        audioPlayerRef.current.addEventListener('ended', () => setIsPlaying(false));
      }
    };
    validateAndSetAudio();
  }, []);

  const handleStartRecording = async () => {
    setShowRecordBtn(false);
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStreamRef.current = stream;
      const recorder = new MediaRecorder(stream);
      mediaRecorderRef.current = recorder;
      setAudioURL('');
      const chunks: Blob[] = [];

      recorder.ondataavailable = (event) => {
        chunks.push(event.data);
      };

      recorder.onstop = () => {
        const file = new File(chunks, 'audio.mp3', { type: 'audio/mp3' });
        if (file) {
          saveAudioInS3(file);
        }
      };

      recorder.start();
      setIsRecording(true);

      const timeout = setTimeout(() => {
        handleStopRecording();
      }, 120000); // 120,000 milliseconds = 2 minutes
      setRecordingTimeout(timeout);
    }
  };

  const handleStopRecording = () => {
    setShowRecordBtn(true);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
    }

    if (recordingTimeout) {
      clearTimeout(recordingTimeout);
      setRecordingTimeout(null);
    }
  };

  const saveAudioInS3 = async (file: File) => {
    try {
      if (file) {
        const data = await s3.uploadFile(file);
        if (data && data.location) {
          const URL = data.location;
          setAudioURL(URL);
          audioPlayerRef.current = new Audio(URL);
          audioPlayerRef.current.addEventListener('ended', () => setIsPlaying(false));
          await saveAudioResponse(URL);
        }
      }
    } catch (e) {
      /**
       * Need to show notification accordingly
       */
    }
  };

  const saveAudioResponse = async (url: string) => {
    try {
      inputContent.map((inputElt: InputContent) => {
        inputElt.questionData.map((questionData) => {
          if (questionData.id === questionId) {
            return questionData.questionFormat.map((questionFormat) => {
              if (questionFormat.format.id === questionFormatId) {
                return (questionFormat.format.response = [url]);
              }
            });
          }
        });
        if (questionFormat.format.mandatory) {
          setIsValid(url.trim().length > 0);
        } else {
          setIsValid(true);
        }
      });

      try {
        const result = await yellowOwlApi.addUserResponse({
          userId: +userId,
          magazineId: +magazineId,
          activityId: activityId,
          inputContent: inputContent,
          tenantId: tenantId,
          categoryId: categoryId,
          skill: skill,
        });
        if (result) {
          /* notification */
          onUpdateInputContents(inputContent);
        }
      } catch (e) {
        /**
         * Need to show notification accordingly
         */
      }
    } catch (exception) {
      console.error('Error saving audio response:', exception);
    }
  };

  const playAudio = () => {
    if (audioPlayerRef.current) {
      if (isPlaying) {
        audioPlayerRef.current.pause();
      } else {
        audioPlayerRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleDelete = () => {
    setOpenConfirmationModal(true);
  };

  const handleConfirmationModalClose = async (userResponse: boolean) => {
    setOpenConfirmationModal(false);
    if (userResponse) {
      if (audioPlayerRef.current) {
        audioPlayerRef.current.pause();
      }
      try {
        const filePath = config.bucketName + '/' + audioURL?.split('.com/')[1];
        await s3.deleteFile(filePath!);
        setIsPlaying(false);
        setAudioURL('');
        await saveAudioResponse('');
      } catch (e) {
        /**
         * Need to show notification accordingly
         */
      }
    }
  };

  function isStringArray(value: any): value is string[] {
    return Array.isArray(value);
  }

  function checkResponse(response: string | string[]): boolean {
    if (typeof response === 'string') {
      return response.trim() !== '';
    } else if (isStringArray(response)) {
      return response.every((res) => res.trim() !== '');
    }
    return false;
  }

  useEffect(() => {
    if (globalInputState) {
      if (questionFormat.format.mandatory) {
        if (questionFormat.format.response && checkResponse(questionFormat.format.response[0])) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      } else {
        setIsValid(true);
      }
    }
  }, [globalInputState]);

  const voiceInputBoxStyle: SxProps<Theme> = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '5px',
    padding: '5px',
    border: isValid ? '4px solid #673AB7' : '4px solid red',
    outline: 'none',
    width: '90%',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  };

  return (
    <div style={{ margin: '2vh 0' }}>
      <Box sx={voiceInputContainerStyle}>
        <Box sx={voiceInputBoxStyle}>
          <IconButton sx={audioPlayBtnStyle} aria-label='mic' onClick={playAudio} disabled={!audioURL}>
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            {isPlaying ? (
              <>
                <img src={SoundAnimation} alt='' width={'85%'} height={'auto'} />
              </>
            ) : (
              <>
                {isRecording ? (
                  <img src={SoundAnimation} alt='' width={'85%'} height={'auto'} />
                ) : (
                  <>
                    {audioURL ? (
                      <img src={SoundBlack} alt='' width={'85%'} height={'auto'} />
                    ) : (
                      <img src={SoundGray} alt='' width={'85%'} height={'auto'} />
                    )}
                  </>
                )}
              </>
            )}
          </Box>
          {!audioURL && (
            <>
              {showRecordBtn ? (
                <Tooltip title='Speak' placement='top'>
                  <IconButton sx={micBtnStyle} aria-label='Start' onClick={handleStartRecording}>
                    <MicIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title='Stop' placement='top'>
                  <IconButton sx={micBtnStyle} aria-label='Stop' onClick={handleStopRecording}>
                    <StopCircleIcon />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
          {audioURL && (
            <Tooltip title='Delete' placement='top'>
              <IconButton sx={micBtnStyle} aria-label='Delete' onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      <ConfirmationModal
        title='Delete Audio'
        content='Are you sure you want to delete this Audio?'
        open={openConfirmationModal}
        onClose={handleConfirmationModalClose}
      />
    </div>
  );
}
