import React, { useContext, useEffect, useState } from 'react';
import { IndexPage } from '../../components/magazine/IndexPage';
import { useSearchParams } from 'react-router-dom';
import { YellowOwlContext } from '../../context/YellowOwlContext';
import { TableOfContents } from '@yellow-owl/client-sdk';
import { useAsyncEffect } from '../../hooks/use-async';
import { useLocalStorage } from '../../context/LocalStorageContext';

export const IndexPageContainer: React.FC = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const title = searchParams.get('name');
  const yellowOwlApi = useContext(YellowOwlContext)!;
  const { state } = useLocalStorage();
  const [tableOfContents, setTableOfContents] = useState<TableOfContents>();
  const [tableOfContentsState] = useAsyncEffect<TableOfContents>({
    fn: async () => {
      const result = await yellowOwlApi.getTableOfContents(state.userId!, id!, state.categoryId!, state.tenantId!);
      return result.data;
    },
    dependencies: [],
  });

  useEffect(() => {
    if (tableOfContentsState.result != undefined) {
      setTableOfContents(tableOfContentsState.result!);
    }
  }, [tableOfContentsState]);

  return <>{tableOfContents && <IndexPage tableOfContents={tableOfContents} magazineId={id} magazineName={title} />}</>;
};
