import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, IconButton, Stack, SxProps, Theme, Typography } from '@mui/material';
import { NavigationPageContext } from '../common/navigation-page/NavigationPage';
import { getInputComponent } from '../../util/ComponentUtil';
import { BookComponent } from '../common/BookComponent';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import activityBackgroundImage from '../../assets/images/bg_title.png';
import { useLocation } from 'react-router-dom';

import {
  InputContent,
  ActivityContent,
  ActivityContentData,
  QuestionData,
  ActivityContentPage,
  ActivitySkillType,
} from '@yellow-owl/client-sdk';
import MagazineRating from '../common/magazine-rating/MagazineRating';
import { LocaluseNavigation } from '../../context/NavigationContextType';
import { useCustomBlocker } from '../../hooks/useCustomBlocker';
import { motion } from 'framer-motion';

const contentStyle: SxProps<Theme> = {
  fontWeight: '600',
  fontSize: '2.5vh',
  fontFamily: 'CormorantGaramondRegular',
  textAlign: 'left',
  lineHeight: '4vh',
  width: '100%',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

const scrollBtnStyle: SxProps<Theme> = {
  position: 'absolute',
  width: '45px',
  height: '45px',
  bottom: '10px',
  right: '-45px',
  cursor: 'pointer',
  backgroundColor: '#3b3077',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10%',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
  zIndex: '1',
  transition: 'bottom 0.3s ease-in-out',
  '&:hover': {
    bottom: '15px',
  },
};

const scrollIcon: SxProps<Theme> = {
  color: '#fbec54',
  fontSize: '4.5vh',
};

const activityFeedbackBoxStyle: SxProps<Theme> = {
  position: 'absolute',
  marginBottom: '-60px',
  bottom: '1px',
  right: '10px',
  zIndex: '1',
  borderRadius: '16px',
  backgroundColor: '#FFF',
};

const activityBgImage = {
  display: 'flex',
  backgroundImage: `url(${activityBackgroundImage});`,
  backgroundSize: 'cover',
  objectFit: 'cover',
  backgroundRepeat: 'no-repeat',
  width: 300,
  alignItems: 'center',
  justifyContent: 'center',
};

const storyTimeStyles = {
  textAlign: 'center',
  display: 'inline-block',
  padding: '16px 14px',
  color: 'white',
  fontSize: '2.5rem',
  borderRadius: '10px',
  fontWeight: 'bold',
  fontFamily: 'Schoolbell',
  lineHeight: '1',
  marginTop: '15px',
};

const skillBoxStyles = {
  marginTop: '15px',
};

const criticalThinkingStyles = {
  display: 'inline-block',
  color: '#3C3077',
  padding: '1px 11px',
  fontSize: '1.3rem',
  borderRadius: '10px',
  position: 'relative',
  left: '100px',
  top: '-10px',
  LineHeight: '1vh',
  fontFamily: 'Schoolbell',
  marginLeft: '10px',
};

const containerStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  background: 'white',
  marginLeft: '-5px',
  // marginBottom: '-45px',
  marginTop: '-8px',
};

type SkillStyle = {
  backgroundColor: string;
};

type SkillName = 'CriticalThinking' | 'Communication' | 'Socioemotional' | 'Creativity' | 'Collaboration';

const skillStyles: Record<SkillName, SkillStyle> = {
  CriticalThinking: { backgroundColor: '#029608' },
  Communication: { backgroundColor: '#F4B840' },
  Socioemotional: { backgroundColor: '#71CCF3' },
  Creativity: { backgroundColor: '#F7EE4B' },
  Collaboration: { backgroundColor: '#F0656B' },
};

const getSkillStyle = (skillName: SkillName) => {
  const baseStyle = skillStyles[skillName] || { backgroundColor: '#FF00FF' };
  return {
    ...baseStyle,
    ...criticalThinkingStyles,
  };
};

export type ActivityPageProps = {
  activityContent: ActivityContent;
  inputContents: Array<InputContent>;
  magazineId: string;
  userId: string;
  tenantId: number;
  categoryId: number;
  activityId: string;
  globalInputState: boolean;
  canShowFeedbackModal: boolean;
  onCloseFeedbackSubmitModal: () => void;
  onCheckMandatory: () => void;
  onUpdateInputContents: (input: Array<InputContent>) => boolean;
};
export const ActivityPage: React.FC<ActivityPageProps> = ({
  activityContent,
  inputContents,
  magazineId,
  userId,
  tenantId,
  categoryId,
  activityId,
  globalInputState,
  canShowFeedbackModal,
  onCloseFeedbackSubmitModal,
  onCheckMandatory,
  onUpdateInputContents,
}) => {
  const navigationPageContext = useContext(NavigationPageContext);
  const pageNos: number[] = navigationPageContext.pageNos;
  const leftPageContentRef = useRef<HTMLDivElement | null>(null);
  const rightPageContentRef = useRef<HTMLDivElement | null>(null);
  const outputContentRef = useRef<HTMLDivElement | null>(null);
  const [showLeftScrollButton, setLeftShowScrollButton] = useState(false);
  const [showRightScrollButton, setRightShowScrollButton] = useState(false);
  const ActivityNamelocation = useLocation();
  const ActivityName = ActivityNamelocation.state.key;
  const magazineName = ActivityNamelocation.state.magazineName;
  console.log(ActivityName, magazineName);

  const { setIsBlocking } = LocaluseNavigation();

  useCustomBlocker(); // Set up the navigation blocker

  useEffect(() => {
    setIsBlocking(true); // Start blocking when the component mounts

    return () => {
      setIsBlocking(false); // Stop blocking when the component unmounts
    };
  }, [setIsBlocking]);

  const playImage = '/static/media/playIcon.5d41e57e795bb4155c789fd1d371650b.svg';
  const pauseImage = '/static/media/pauseIcon.ef0c6ba7bd854b85303bf632f9b1c3b6.svg';

  useEffect(() => {
    const outputContainer = outputContentRef.current;
    if (outputContainer) {
      const playPauseImages = outputContainer.querySelectorAll<HTMLImageElement>('.play-pause');
      playPauseImages.forEach((playPauseImage) => {
        const audioElement = playPauseImage.nextElementSibling;
        if (audioElement instanceof HTMLAudioElement) {
          audioElement.addEventListener('ended', () => {
            playPauseImage.src = playImage;
            playPauseImage.alt = 'Play';
          });
          const handleClick = () => {
            if (audioElement.paused) {
              audioElement.play();
              playPauseImage.src = pauseImage;
              playPauseImage.alt = 'Pause';
            } else {
              audioElement.pause();
              playPauseImage.src = playImage;
              playPauseImage.alt = 'Play';
            }
          };
          playPauseImage.onclick = handleClick;
        }
      });
    }

    return () => {
      if (outputContainer) {
        const playPauseImages = outputContainer.querySelectorAll<HTMLImageElement>('.play-pause');
        playPauseImages.forEach((playPauseImage) => {
          playPauseImage.onclick = null;
        });
      }
    };
  }, [activityContent, pageNos]);

  const handleScrollDown = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      const newScrollTop = ref.current.scrollTop + ref.current.clientHeight / 2;
      ref.current.scrollTo({
        top: newScrollTop,
        behavior: 'smooth',
      });
    }
  };

  const updateScrollButtons = () => {
    if (leftPageContentRef.current) {
      setLeftShowScrollButton(leftPageContentRef.current.scrollHeight > leftPageContentRef.current.clientHeight);
    }
    if (rightPageContentRef.current) {
      setRightShowScrollButton(rightPageContentRef.current.scrollHeight > rightPageContentRef.current.clientHeight);
    }
  };

  const renderPageContent = (
    activityData: ActivityContentData,
    ref: React.RefObject<HTMLDivElement>,
    activitySkills: Array<ActivitySkillType>
  ) => {
    const currentPageNumber = activityData.pageNumber;
    const lastPage = activityContent.noOfPages;
    const isLastPage = currentPageNumber === lastPage;
    return (
      <Box key={activityData.id} sx={contentStyle} ref={ref}>
        {activityData.pageNumber == 1 && (
          <Box sx={containerStyles}>
            <Box sx={activityBgImage}>
              <Typography sx={storyTimeStyles}>{ActivityName}</Typography>
            </Box>
            <Box sx={skillBoxStyles}>
              {activitySkills.map((skillName: string) => (
                <Typography sx={getSkillStyle(skillName as any)} key={skillName}>
                  {skillName}
                </Typography>
              ))}
            </Box>
          </Box>
        )}
        {activityData.pageData.type === 'Output' && (
          <div ref={outputContentRef}>
            {activityData.pageData.data.map((htmlString: string, idx: number) => (
              <div key={idx} className={'ck-content'} dangerouslySetInnerHTML={{ __html: htmlString }} />
            ))}
          </div>
        )}
        {activityData.pageData.type === 'Input' && (
          <div className={'ck-content'} style={{ width: '100%' }}>
            {activityData.pageData.data.map((qd: InputContent, ins: number) => (
              <div key={ins} className='marginY2vh'>
                {qd.questionData.map((question: QuestionData, index: number) => (
                  <div key={question.id}>
                    {getInputComponent(
                      magazineId,
                      magazineName,
                      ActivityName,
                      userId,
                      question,
                      index,
                      inputContents!,
                      activityId,
                      tenantId,
                      categoryId,
                      activityContent.skill,
                      globalInputState,
                      onUpdateInputContents
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
        {activityData.pageData.type === 'Mixed' && (
          <>
            {activityData.pageData.data.map((mixedPageData: ActivityContentPage, ins: number) => (
              <div key={ins}>
                {mixedPageData.type === 'Output' && (
                  <>
                    {mixedPageData.data.map((htmlString: string, idx: number) => (
                      <div key={idx} className={'ck-content'} dangerouslySetInnerHTML={{ __html: htmlString }} />
                    ))}
                  </>
                )}
                {mixedPageData.type === 'Input' && (
                  <div className={'ck-content'} style={{ width: '100%' }}>
                    {mixedPageData.data.map((qd: InputContent, ins: number) => (
                      <div key={ins}>
                        {qd.questionData.map((question: QuestionData, index: number) => (
                          <div key={question.id}>
                            {getInputComponent(
                              magazineId,
                              magazineName,
                              ActivityName,
                              userId,
                              question,
                              index,
                              inputContents!,
                              activityId,
                              tenantId,
                              categoryId,
                              activityContent.skill,
                              globalInputState,
                              onUpdateInputContents
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </>
        )}
        {isLastPage && (
          <Box sx={activityFeedbackBoxStyle}>
            <MagazineRating
              userId={userId}
              magazineId={magazineId}
              activityId={activityId}
              // TODO: remove the below property
              rating={3}
              tenantId={tenantId}
              categoryId={categoryId}
              canShowFeedbackModal={canShowFeedbackModal}
              onCheckMandatory={onCheckMandatory}
              onCloseFeedbackSubmitModal={onCloseFeedbackSubmitModal}
            />
          </Box>
        )}
      </Box>
    );
  };

  const leftContent = (
    <Box sx={{ width: '35vw' }}>
      <Stack height={'80vh'} sx={{ position: 'relative' }}>
        {activityContent.data
          .filter(
            (activityData: ActivityContentData) =>
              Number(activityData.pageNumber) % 2 === 1 && pageNos.includes(Number(activityData.pageNumber))
          )
          .map((activityData) => renderPageContent(activityData, leftPageContentRef, activityContent.skill))}

        {showLeftScrollButton && (
          <Box>
            <motion.div
              style={scrollBtnStyle}
              animate={{ bottom: ['20px', '25px', '20px'] }}
              transition={{ duration: 5, ease: 'easeInOut', repeat: Infinity }}
            >
              <IconButton onClick={() => handleScrollDown(leftPageContentRef)}>
                <KeyboardArrowDownIcon sx={scrollIcon} />
              </IconButton>
            </motion.div>
          </Box>
        )}
      </Stack>
    </Box>
  );

  const rightContent = (
    <Box sx={{ width: '35vw' }}>
      <Stack height={'80vh'} sx={{ position: 'relative' }}>
        {activityContent.data
          .filter(
            (activityData) =>
              Number(activityData.pageNumber) % 2 === 0 && pageNos.includes(Number(activityData.pageNumber))
          )
          .map((activityData) => renderPageContent(activityData, rightPageContentRef, activityContent.skill))}
        {showRightScrollButton && (
          <Box>
            <motion.div
              style={scrollBtnStyle}
              animate={{ bottom: ['10px', '15px', '10px'] }}
              transition={{ duration: 2, ease: 'easeInOut', repeat: Infinity }}
            >
              <IconButton onClick={() => handleScrollDown(leftPageContentRef)}>
                <KeyboardArrowDownIcon sx={scrollIcon} />
              </IconButton>
            </motion.div>
          </Box>
        )}
      </Stack>
    </Box>
  );

  useEffect(() => {
    const listenResize = new ResizeObserver((entries) => {
      entries.forEach(() => {
        updateScrollButtons();
      });
    });
    listenResize.observe(leftPageContentRef.current!);
    listenResize.observe(leftPageContentRef.current!);
  }, [rightContent, leftContent]);

  return (
    <>
      <BookComponent leftContent={leftContent} rightContent={rightContent} hideButtons={true} />;
    </>
  );
};
