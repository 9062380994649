import React from 'react';
import { Stack, SxProps, Theme, Typography, styled, Tooltip, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ActivitySkillType } from '@yellow-owl/client-sdk';
import CompletedActivity from '../../../assets/images/completed-activity.png';
import { NavigationParams } from '../../magazine/IndexPage';

const rightPageIconTitle: SxProps<Theme> = {
  fontWeight: '700',
  fontSize: '2.3vh',
  fontFamily: 'CormorantGaramondRegular',
  textAlign: 'center',
};

const StyledVideo = styled('video')(() => ({
  width: '12vh',
  height: 'auto',
}));

const statusIcon: SxProps<Theme> = {
  width: '12vh',
  height: 'auto',
};

const ActivityCompletedImg = {
  width: '40px',
  height: '40px',
  position: 'absolute',
  top: '5px',
  right: '0.5px',
  '& img': {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
};

type ActivityStatusType = {
  type: string;
  url: string;
};

export type IndexItemComponentProps = {
  itemName: string | undefined;
  itemIconImage?: ActivityStatusType;
  navigateTo: NavigationParams;
  sx?: SxProps<Theme>;
  skill?: Array<ActivitySkillType>;
  isCompleted?: boolean;
};
export const IndexItem: React.FC<IndexItemComponentProps> = ({
  itemName,
  sx,
  itemIconImage,
  navigateTo,
  skill,
  isCompleted,
}) => {
  const navigator = useNavigate();

  const onClickItem = () => {
    navigator(navigateTo.pathname + navigateTo.search, {
      state: {
        ...navigateTo.state,
        activityStatus: isCompleted,
      },
    });
  };
  return (
    <Stack sx={sx} onClick={onClickItem}>
      {isCompleted && (
        <Box sx={ActivityCompletedImg}>
          <img src={CompletedActivity} alt='ActivityCompleted' />
        </Box>
      )}
      {itemIconImage?.type === 'IMAGE' && <img src={itemIconImage.url} />}
      {itemIconImage?.type === 'ANIMATED' && (
        <StyledVideo sx={statusIcon} src={itemIconImage.url} autoPlay loop muted />
      )}
      <Tooltip title={skill?.join(', ')}>
        <Typography gutterBottom sx={rightPageIconTitle} variant={'body1'}>
          {itemName}
        </Typography>
      </Tooltip>
    </Stack>
  );
};
